<template>
  <div class="clients mt-5 py-4 mt-sm-5 py-sm-5">
    <b-container class="mt-5 my-sm-5">
      <b-row>
        <b-col sm="12">
          <h1 class="main-heading">
            {{ $t("heading.our_clients") }}
          </h1>
        </b-col>
      </b-row>

      <!-- Client Cards -->
      <b-row class="mt-5" v-if="!fetchLoading">
        <b-col
          class="mb-4"
          lg="3"
          md="6"
          sm="12"
          cols="12"
          v-for="(item, index) in clients"
          :key="index"
        >
          <client-card :item="item"></client-card>
        </b-col>
      </b-row>

      <!-- CardLoaders -->
      <b-row v-else>
        <b-col xl="3" lg="6" sm="6" cols="12" v-for="index in 10" :key="index">
          <card-loader></card-loader
        ></b-col>
      </b-row>
    </b-container>
    <div class="text-xs-center mt-4">
      <b-pagination
        v-model="pagination.current_page"
        align="center"
        size="md"
        :per-page="pagination.per_page"
        :total-rows="pagination.total"
        hide-goto-end-buttons
        @change="handlePagination"
        pills
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";
export default {
  name: "Clients",
  components: {
    ClientCard: () => import("@/components/Cards/Client")
    // CardLoader: () => import("@/components/CardLoader")
  },
  data() {
    return {
      clients: [],
      pagination: {},
      fetchLoading: false
    };
  },
  created() {
    this.fetchClients();
  },
  methods: {
    async fetchClients() {
      try {
        const page = this.$route.query.page || 1;
        this.fetchLoading = true;
        const { data } = await IndexData({
          reqName: "clients",
          query: { page }
        });
        this.clients = data.clients;
        this.pagination = data.meta;
      } catch (err) {
        console.log(err);
      } finally {
        this.fetchLoading = false;
      }
    },
    handlePagination(page) {
      this.$router.push({ query: { page } });
      this.fetchClients();
    }
  }
};
</script>

<style lang="scss" scoped></style>
